import React from 'react';
import {
  Box,
} from '@mui/material';
import TdcProductsCard from '../TdcProductsCard';
import reactivationLogo from '../../assets/tdc_product_logos/reactivation-mark.png';

function HouseFile() {
  const houseFileArray = [
    {
      logo: reactivationLogo,
      title: 'Reactivation',
      chipText: [],
      chipColor: [],
      upperText: 'The Digital Co-Op\'s powerful modeling infrastructure extracts detailed behavioral data from your CRMs and ESPs with an unmatched level of precision. Using this behavioral data, donation history, demographic information, and massive amounts of digital exhaust data, these sophisticated models score your inactive audience, identifying those supporters most likely to reactivate. We often talk about this as “shopping in your own closet” for highly qualified leads that have already demonstrated interest in an organization\'s mission.',
      lowerText: 'Programs looking to rekindle engagement with supporters from their existing lapsed database. This is great for organizations with mature programs, as well as a way to treat emergency response donors or refresh a lapsed or unengaged segment.',
      buttonText: 'Request a delivery',
      deliveryWindow: 'The delivery timeline for email products is 2 business days. The delivery kickoff window is between 9:30 AM-12:30 PM ET daily.',
      pricing: 'CPA per client varies. Total cost per buy must meet a $7,500 minimum unless approved by Ambika and Alex Stein.',
      helpDocumentation: [{ text: 'How to submit a delivery', link: 'https://missionwired.atlassian.net/wiki/spaces/AAI/pages/3968434244/How+to+Order+AdvantageAI+Email+Reactivation+Delivery' }, { text: 'Strategy Levers', link: 'https://missionwired.atlassian.net/wiki/spaces/AAI/pages/3968434177/AdvantageAI+Email+Reactivations' }],
      collapseOption: true,
    },
    {
      logo: '',
      title: 'MissionSMS List Tiering',
      chipText: [],
      chipColor: [],
      upperText: `This rental service models and identifies supporters completely new to an organization’s universe who are most likely to engage and donate via SMS. Because these supporters are new-to-file, they must be texted via P2P in order to be opted into a Broadcast program. Clients can keep data from audience members who convert (e.g., by donating or sharing their contact information).
          With the Managed Service, MissionWired will model to identify the best possible audience and then deploy text messages on the Co-Op member’s behalf using Scale to Win. With Data Only, the client will receive a list from MissionWired – and then send text messages themselves either once or twice (depending on pricing choice) to that list of numbers. Leads should be pushing for Managed Services buys with their clients.`,
      lowerText: 'Nonprofit and political programs eager to grow their Broadcast file and drive additional revenue in key moments (campaign launches, Giving Tuesday, rapid response, etc.).',
      buttonText: '',
      deliveryWindow: '',
      pricing: '',
      helpDocumentation: [{ text: 'List Tiering FAQ', link: 'https://missionwired.atlassian.net/wiki/spaces/AAI/pages/3340271617/MissionSMS+List+Score' }],
      collapseOption: true,
    },
    {
      logo: '',
      title: 'Facebook Custom Audience',
      chipText: [],
      chipColor: [],
      upperText: 'Facebook Custom Audience is a house file product that allows Co-op Members to request segments up to 8 audiences (4 quartiles of donors, 4 quartiles of prospects), and upload to Facebook for ad targeting.',
      lowerText: 'This feature was developed due to privacy laws that prevented Facebook from being able to optimize a list for Ad targeting. Because of this, Facebook Custom Audience using AdvantageAI technology will be capable of optimizing lists for Ad Spend for both political and non-profit organizations.',
      buttonText: '',
      deliveryWindow: 'Two business days.',
      pricing: 'Varies. Typical fee is $5,000 per month.',
      helpDocumentation: [],
      collapseOption: false,
    },
  ];

  return (
    <Box>
      {houseFileArray.map((item) => (
        <TdcProductsCard
          logo={item.logo}
          title={item.title}
          upperText={item.upperText}
          lowerText={item.lowerText}
          buttonText={item.buttonText}
          chipText={item.chipText}
          chipColor={item.chipColor}
          deliveryWindow={item.deliveryWindow}
          pricing={item.pricing}
          helpDocumentation={item.helpDocumentation}
          collapseOption={item.collapseOption}
        />
      ))}
    </Box>
  );
}

export default HouseFile;

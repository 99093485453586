/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import logger from '../../utils/logger';

class ClientForm extends Component {
  constructor(props) {
    super(props);

    let isDisabled = true;
    if (props.permissions != null) {
      if (Object.keys(props.permissions).length > 0) {
        if (
          props.permissions[props.attribute] !== false
          && props.permissions[props.attribute] !== undefined
          && props.permissions[props.attribute] !== null
        ) {
          isDisabled = props.attribute in props.permissions
            ? !props.permissions[props.attribute]
            : false;
        }
      }
    }
    // remove this when we fetch data
    isDisabled = false;

    this.state = {
      adornment: props.inputAdornment || '',
      validator: props.validateFunc || this.handleMissingValidator,
      setter: props.setFunction || this.handleMissingSetter,
      attribute: props.attribute || '',
      structure: props.structure || {},
      isDisabled,
      label: props.label || 'N/A',
      required: props.required || false,
      data: Array.isArray(props.data) ? props.data.join(',') : props.data,
      testingId: props.testingId ? props.testingId : '',
      inputType: props.inputType || 'text', // this does not affect the input field
      type: props.type || 'text', // this affects the input field.
    };

    this.updateClientState = this.updateClientState.bind(this);
    this.updateClientStateCheckbox = this.updateClientStateCheckbox.bind(this);
    this.updateFormValidity = this.updateFormValidity.bind(this);
  }

  handleMissingValidator() {
    /**
     * This function is a placeholder for the case when a field is missing a validator.
     * Will default to accepting all values as valid.
     * @returns {void}
     */
    const { attribute, validator } = this.state;
    logger.debug(`${attribute} field needs a validator, all values will be accepted as valid for now.`);
    validator(true);
  }

  handleMissingSetter() {
    /**
     * This function is a placeholder for the case when a field is missing a setter.
     * Will default to logging a message to the console.
     * @returns {void}
     */
    const { attribute } = this.state;
    logger.debug(`${attribute} field needs a setter`);
  }

  updateFormValidity(bool) {
    /**
     * Used to update the validity of the form on the ClientInfo component level.
     * @param {boolean} bool - Whether or not this particular input is valid.
     * @returns {void}
     */
    const { validator } = this.state;
    validator(bool);
  }

  updateClientState(event) {
    /**
     * Used to update the state of the client form on the ClientInfo component level.
     * @param {object} event - The event object.
     * @returns {void}
     */
    const { setter, attribute, label } = this.state;
    if (attribute === 'p2p_source') {
      setter(attribute, event.data);
    } else {
      setter(attribute, event.target.value, label);
    }
  }

  updateClientStateCheckbox(event) {
    /**
     * Used to update the state of the client form on the ClientInfo component level for checkboxes.
     * @param {object} event - The event object.
     * @returns {void}
     */
    const { attribute, setter } = this.state;
    setter(attribute, event.target.checked);
    this.setState(
      { data: event.target.checked, [attribute]: event.target.checked },
    );
  }

  render() {
    return <>test</>;
  }
}

export default ClientForm;

ClientForm.propTypes = {
  permissions: PropTypes.oneOfType([PropTypes.object]),
  attribute: PropTypes.string.isRequired,
  setFunction: PropTypes.func.isRequired,
  validateFunc: PropTypes.func,
  inputAdornment: PropTypes.string,
  structure: PropTypes.oneOfType([PropTypes.object]),
  label: PropTypes.string,
  required: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.any]),
  testingId: PropTypes.string,
  type: PropTypes.string,
  inputType: PropTypes.string,
};

/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ReportsCard from '../../components/ReportsCard';
// card images
import stratClientRevenue from '../../assets/reporting_views/tdc_strat_clientRev.png';
// import stratCoopDash from '../../assets/reporting_views/tdc_strat_coopDash.png';
import stratFixedCostDash from '../../assets/reporting_views/tdc_strat_fixedCostDash.png';
import stratMarketingTrends from '../../assets/reporting_views/tdc_strat_marketingTrends.png';
import stratAggResultsBySubset from '../../assets/reporting_views/tdc_strat_aggResultsBySubset.png';

function TDCStrategyLanding() {
  /**
   * Component used for displaying various Benchmarking and Strategy report summaries with links to the full reports
   */
  useEffect(() => {
    document.title = 'MissionPortal | TDC Benchmarking and Strategy';
  }, []);
  const { client } = useParams();

  return (
    <div className="App">
      <header className="App-header">
        <h2>The Digital Co-Op Benchmarking & Strategy</h2>
      </header>
      <h5>
        Track performance and client metrics across the Digital Co-Op.
      </h5>

      <Box sx={{ flexGrow: 1 }}>
        <div className="reporting-row-container">
          <ReportsCard
            image={stratClientRevenue}
            imageAlt="Client Digital Revenue Trends view"
            title="Client Digital Revenue Trends"
            description="See digital revenue (not just TDC revenue) for each client by week and month, and compare revenue trends to politicals or nonprofits overall."
            bullets={[]}
            route={`/client/${client}/Co-Op/Strategy/client_revenue`}
            buttonText="View Report"
          />
          {/* <ReportsCard
            image={stratCoopDash}
            imageAlt="Digital Co-Op Dashboard view"
            title="Digital Co-Op Dashboard"
            description="Provides general information about The Digital Co-Op membership, total names delivered, and average price per name across acquisitions, reactivations, and sms deliveries."
            bullets={[]}
            route={`/client/${client}/Co-Op/Strategy/digital_coop_dashboard`}
            buttonText="View Report"
          /> */}
          <ReportsCard
            image={stratFixedCostDash}
            imageAlt="Fixed Cost Dashboard view"
            title="Fixed Cost Landscape"
            description="Provides information on various acquistions sources, including but also outside of TDC. This will show how many names are being acquired by each source, frequency of buys, VPN, and pathway to breakeven per client."
            bullets={[]}
            route={`/client/${client}/Co-Op/Strategy/fixed_cost_landscape`}
            buttonText="View Report"
          />
        </div>
        <div className="reporting-row-container">
          <ReportsCard
            image={stratMarketingTrends}
            imageAlt="Digital Fundraising Trends view"
            title="Digital Fundraising Trends"
            description="Provides a pulse check on current digital trends for politicals and nonprofits, and comparisons to previous years."
            bullets={[]}
            route={`/client/${client}/Co-Op/Strategy/political_marketing_trends`}
            buttonText="View Report"
          />
          <ReportsCard
            image={stratAggResultsBySubset}
            imageAlt="Aggregate Results by Subset"
            title="Aggregate Results by Subset"
            description="This report splits aggregate results into various user-selected subsets. For instance, the Senate_2024 tab shows aggregate results only for clients that were identified as 2024 senate candidates. Clicking 'View Report' will open a google sheet with these results."
            bullets={[]}
            externalPageURL="https://docs.google.com/spreadsheets/d/1TFc7EMdPi3JkHdblUz33RON0w6Sy-Dm1fctncE97b9k/edit#gid=1186538393"
            buttonText="View Report"
          />
        </div>
      </Box>
    </div>
  );
}

export default TDCStrategyLanding;

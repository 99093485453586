/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ReportsCard from '../../components/ReportsCard';
// card images
import acqInventory from '../../assets/reporting_views/tdc_acq_inventory.png';
import acqMetrics from '../../assets/reporting_views/tdc_acq_metrics.png';
import acqROIAnalysis from '../../assets/reporting_views/tdc_acq_roiAnalysis.png';
import acqDelivChecks from '../../assets/reporting_views/tdc_acq_deliveryChecks.png';
import acqResults from '../../assets/reporting_views/tdc_acq_results.png';

function TDCAcquisitionsLanding() {
  /**
   * Component used for displaying various TDC Acquisition report summaries with links to the full reports
   */
  useEffect(() => {
    document.title = 'MissionPortal | TDC Email Acquisition';
  }, []);
  const { client } = useParams();

  return (
    <div className="App">
      <header className="App-header">
        <h2>The Digital Co-Op Email Acquisition</h2>
      </header>
      <h5>
        Grow your client&rsquo;s list with new names from other co-op members. Learn more about Digital Co-Op Email Acquisition in the
        <a target="_blank" href="https://missionwired.atlassian.net/wiki/spaces/AAI/overview" rel="noreferrer"> Digital Co-Op Confluence Space.</a>
      </h5>

      <Box sx={{ flexGrow: 1 }}>
        <h3>Pre-Delivery</h3>
        <div className="reporting-row-container">
          <ReportsCard
            image={acqInventory}
            imageAlt="Acquistions report"
            title="TDC Acquisition - Preferred Inventory"
            description="Preferred Inventory available for each client"
            bullets={['Breakdown by Domain and State', 'Composition of Inventory Available (30 day clickers, 30 day donors, average gifts)', 'Inventory constrained clients']}
            route={`/client/${client}/Co-Op/Acquisition/preferred_inventory`}
            buttonText="View Report"
          />
        </div>
        <h3>Before Uploading to DropBox</h3>
        <div className="reporting-row-container">
          <ReportsCard
            image={acqDelivChecks}
            imageAlt="QA Dash view"
            title="Delivery QA Dash"
            description="Compares what was requested in the JIRA ticket to what was delivered."
            bullets={[]}
            route={`/client/${client}/Co-Op/Acquisition/delivery_qa_dashboard`}
            buttonText="View Report"
          />
          <ReportsCard
            image={acqMetrics}
            imageAlt="Acquistions Delivery Metrics view"
            title="Acquisition Delivery Metrics"
            description="Compares what was requested in the JIRA ticket to what was delivered."
            bullets={['Donor Metrics - % of donors within each delivery', 'Email Events - % of cohort with clicks or opens', 'Metrics Summary - summarizing table of donor % and email events']}
            route={`/client/${client}/Co-Op/Acquisition/acquisition_delivery_metrics`}
            buttonText="View Report"
          />
        </div>
        <h3>Post Delivery Upload</h3>
        <div className="reporting-row-container">
          <ReportsCard
            image={acqROIAnalysis}
            imageAlt="Internal Performance view"
            title="Acquisition Internal Performance Analysis"
            bullets={['Breakeven dates of TDC Acquisition deliveries (actual and projected)', 'Cumulative ROI of TDC Acquisition deliveries, based on days since delivery, to compare performance of recent deliveries to past deliveries', 'Cumulative ROI of TDC Acquisition deliveries, based on the date of delivery, to reveal performance bumps from specific dates']}
            route={`/client/${client}/Co-Op/Acquisition/acquisition_performance_analysis`}
            buttonText="View Report"
          />
          <ReportsCard
            image={acqResults}
            imageAlt="Aggregate Results view"
            title="JIRA TDC Acquisition Aggregate Results"
            bullets={['Tab 1 groups all names delivered under the same JIRA ticket', 'Tab 2 groups all names delivered per cohort per JIRA ticket (if there were 3 cohorts provided in one JIRA ticket, they would be displayed on 3 rows)']}
            route={`/client/${client}/Co-Op/Acquisition/aggregate_results`}
            buttonText="View Report"
          />
        </div>
      </Box>

    </div>
  );
}

export default TDCAcquisitionsLanding;
